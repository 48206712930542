import { Card, Row } from 'antd';
import React from 'react'
import Heading from '../../../component/common/Heading';
import CRUDComponent from '../../../component/common/CRUD-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { useNavigate } from 'react-router-dom';
import { PERMISSION } from '../../../util/functions';
import { brandExportData } from '../../../util/exportData';

const Brand = ({
    admindetails,
    permissionId
}) => {
    const navigate = useNavigate();
    const accessPermission = +admindetails?.access?.split(',')?.filter(ele => ele.includes(permissionId))[0]?.split('-')[1];
    return (
        <>
            <div>
                <Card className="my-5">
                    <Heading>Brand List</Heading>
                    <Row>
                        <CRUDComponent
                            title='Brand'
                            exportData={brandExportData}
                            accessPermission={accessPermission}
                            GET={{
                                API: CONSTANTS.API.Brand_Management.getAll,
                                DataModifier: (res, API, Setrefresh) => {
                                    return res?.map(data => {
                                        return {
                                            ...data,
                                            no: `B${data?.id.toString().padStart(7, "0")}`,
                                            view: {
                                                id: data?.id,
                                                onClick: (id) => {
                                                    navigate(`/app/brand/${id}`)
                                                }
                                            },
                                            isBlockedD: data?.isBlocked ? 'Yes' : 'No',
                                            toggle: {
                                                checked: !data?.isBlocked,
                                                id: data?.id,
                                                isDisable: PERMISSION[accessPermission] === 'READ',
                                                onClick: (id, val) => {
                                                    const UpdateAPIEnd = { ...CONSTANTS?.API.Brand_Management.update };
                                                    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                                                    API.sendRequest(
                                                        UpdateAPIEnd,
                                                        () => {
                                                            Setrefresh((prev) => !prev);
                                                        },
                                                        { isBlocked: val },
                                                        `Brand ${val ? "Blocked" : "Unblock"} Successfully`
                                                    );
                                                },

                                            },


                                        }
                                    });
                                },
                                column: CONSTANTS.TABLE.BRAND,
                            }}
                            isSearch
                            CREATE={{
                                API: CONSTANTS.API.Brand_Management.add,
                                isFormData: true,
                                message: "Created Brand successfully",
                                modaltitle: "Add New Brand",
                                modalFields: CONSTANTS.FORM_FIELD.BRAND_MODAL,
                                // payloadModifier: (res) => res,
                            }}
                            UPDATE={{
                                API: CONSTANTS.API.Brand_Management.update,
                                isFormData: true,
                                message: "Edit Brand successfully",
                                modaltitle: "Edit Brand",
                                modalFields: CONSTANTS.FORM_FIELD.EDIT_BRAND_MODAL,
                                // payloadModifier: (res) => res,
                            }}
                            DELETE={{
                                API: CONSTANTS.API.Brand_Management.delete,
                                message: "Deleted Brand Successfully",
                            }}
                        />
                    </Row>
                </Card>

            </div>
        </>
    )
}

export default Brand