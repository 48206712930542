import { Card, notification, Tabs } from "antd";
import React from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import General from "./tabs/General";
import Category from "./tabs/Category";
import Testimonials from "./tabs/Testimonials";
import FAQ from "./tabs/FAQ";
import SpinTerm from "./tabs/SpinTerm";
import Policy from "./tabs/Policy";
import Terms from "./tabs/Terms";
import WorkAgreement from "./tabs/WorkAgreement";
import Notification from "./tabs/Notification";

const Setting = ({ admindetails, permissionId }) => {
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  const menus = {
    general: <General accessPermission={accessPermission} />,
    category: <Category accessPermission={accessPermission} />,
    testimonials: <Testimonials accessPermission={accessPermission} />,
    notification: <Notification accessPermission={accessPermission} />,
    faq: <FAQ accessPermission={accessPermission} />,
    spin: <SpinTerm accessPermission={accessPermission} />,
    policy: <Policy accessPermission={accessPermission} />,
    terms: <Terms accessPermission={accessPermission} />,
    agreement: <WorkAgreement accessPermission={accessPermission} />,
  };

  // console.log(accessPermission);

  return (
    <Card className="my-5">
      <Tabs
        className="p-0 m-0"
        size="middle"
        type="card"
        items={CONSTANTS.TAB_MENU.SETTING.map((ele) => {
          return {
            label: `${ele.Label}`,
            key: `${ele.id}`,
            children: menus[ele?.id],
          };
        })}
      />
    </Card>
  );
};

export default Setting;
