import { Card, Image, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import noImg from "../../../asset/image/no image.png";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import {
  apiGenerator,
  PERMISSION,
  TASK_LABEL_OPTION,
  TASK_STATUS_COLOR,
} from "../../../util/functions";
import useHttp from "../../../hooks/use-http";
import CRUDComponent from "../../../component/common/CRUD-Component";
import ArrowIcon from "../../../component/common/ArrowIcon";
import { particularTaskExportData } from "../../../util/exportData";

const BrandDetail = ({ admindetails, permissionId }) => {
  const [brandData, setBrandData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();
  const api = useHttp();
  const navigate = useNavigate();
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  useEffect(() => {
    const BRAND_DETAIL_API = apiGenerator(
      CONSTANTS.API.Brand_Management.getOne,
      { id }
    );
    api.sendRequest(BRAND_DETAIL_API, (res) => {
      setBrandData(res?.data);
    });
  }, []);
  return (
    <>
      <div className="mt-5 flex items-center gap-2">
        <ArrowIcon
          onClick={() => navigate(-1)}
          className={"cursor-pointer"}
          size={27}
        />
        <p className="font-medium text-2xl">Brand Details </p>
      </div>
      <Card className="my-5 w-[35%] p-5 2xl:w-[30%]" bodyStyle={{ padding: 0 }}>
        <div className="flex items-center gap-2">
          <div>
            <Image
              className=" object-cover"
              width={100}
              height={100}
              src={brandData?.logo || noImg}
              alt="user profile"
            />
          </div>
          <div>
            <p className="font-medium text-3xl">{brandData?.name}</p>
          </div>
        </div>
      </Card>
      <div>
        <Card className="my-5">
          <Row>
            <CRUDComponent
              title="Brand Task"
              exportData={particularTaskExportData}
              accessPermission={accessPermission}
              reload={refresh}
              GET={{
                API: CONSTANTS.API.Task_Management.getAll,
                extraQuery: {
                  brandId: id,
                },
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      no: `BT${data?.id.toString().padStart(7, "0")}`,
                      category: data?.taskCategory?.name,
                      amount: data?.completionAmount,
                      statusD: data?.status,
                      status: (
                        <p style={{ color: TASK_STATUS_COLOR[data?.status] }}>
                          {data?.status}
                        </p>
                      ),
                      label: (
                        <Select
                          className="w-28"
                          placeholder={"Select a Status"}
                          isDisable={PERMISSION[accessPermission] === "READ"}
                          value={data?.tag}
                          onChange={(value) => {
                            // setTag({
                            //     id: data?.id,
                            //     tag: value
                            // })
                            const UPDATE_TAG_API = apiGenerator(
                              CONSTANTS.API.Task_Management.update,
                              {},
                              `${data?.id}`
                            );
                            api.sendRequest(
                              UPDATE_TAG_API,
                              (res) => {
                                setRefresh((prev) => !prev);
                              },
                              { tag: value },
                              "Update a Tag Successfully!!!"
                            );
                            // warning()
                          }}
                          allowClear // Allow clearing the selected value
                        >
                          {TASK_LABEL_OPTION.map((item) => (
                            <Select.Option
                              key={`role_${item.id}`}
                              value={item.value}
                            >
                              {item.label ? item.label : item.value}
                            </Select.Option>
                          ))}
                        </Select>
                      ),
                      view: {
                        id: data?.id,
                        onClick: (dataId) => {
                          navigate(`/app/brand/${id}/task/${dataId}`);
                        },
                      },
                    };
                  });
                },
                column: CONSTANTS.TABLE.BRAND_TASK,
              }}
              isSearch
              UPDATE={{
                API: CONSTANTS.API.Task_Management.update,
                isFormData: true,
                message: "Edit Task successfully",
                modaltitle: "Edit Task",
                modalFields: CONSTANTS.FORM_FIELD.EDIT_BRAND_MODAL,
                // payloadModifier: (res) => res,
              }}
              DELETE={{
                API: CONSTANTS.API.Task_Management.delete,
                message: "Deleted Task Successfully",
              }}
            />
          </Row>
        </Card>
      </div>
    </>
  );
};

export default BrandDetail;
