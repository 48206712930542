import { Button, Card, Form, Row, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import useHttp from "../../hooks/use-http";
import FormFields from "./FormFields";
import { getClearObject } from "../../util/functions";

const RichTextForm = ({
    Title = "",
    BaseData,
    menuFields = [],
    EndpointObject,
    successMessage = "Updated Successfully",
    Refresher = () => { },
    disabled = false,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});

    // console.log(BaseData);

    const API = useHttp();
    const handleEdit = () => {
        setIsEditing(true);
    };
    useEffect(() => {
        // console.log(BaseData, Title);
        setEditedData(BaseData);
    }, [BaseData]);
    const handleFormSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                const payload = getClearObject(values);

                if (EndpointObject && EndpointObject.endpoint && EndpointObject.type) {
                    API.sendRequest(
                        EndpointObject,
                        (res) => {
                            setIsEditing(false);
                            setEditedData(values);
                            Refresher();
                        },
                        payload,
                        successMessage
                    );
                } else {
                    notification.error({
                        message: "Something Went Wrong",
                        duration: 3,
                    });
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
                notification.error({
                    message: "Something Went Wrong",
                    duration: 3,
                });
            });
    };
    const [form] = Form.useForm();
    return (
        <div className="mt-5">
            <Typography.Title
                level={3}
                className="flex justify-between p-3 !text-white"
            // className="flex justify-between p-3 "
            >
                {/* {Title} */}
                {!disabled && (
                    <Row>
                        {isEditing ? (
                            <Row className="gap-5">
                                <Button
                                    type="primary"
                                    onClick={handleFormSubmit}
                                    loading={API.isLoading}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        // setEditedData({});
                                        setIsEditing(false);
                                    }}
                                    loading={API.isLoading}
                                >
                                    Cancel
                                </Button>
                            </Row>
                        ) : !!editedData?.[menuFields?.[0]?.id] ? (
                            <Button danger onClick={handleEdit} loading={API.isLoading}>
                                Edit
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                onClick={handleEdit}
                                loading={API.isLoading}
                            >
                                Add
                            </Button>
                        )}
                    </Row>
                )}
            </Typography.Title>
            <Card
                bordered={false}
                style={{
                    borderRadius: 0,
                }}
                styles={{
                    body: {
                        paddingTop: 0,
                    },
                }}
            >
                {isEditing ? (
                    <FormFields
                        name={Title}
                        menuFields={menuFields}
                        formData={editedData}
                        form={form}
                        disabled={!isEditing}
                    />
                ) : (
                    menuFields?.[0]?.type !== "richTextarea" ? editedData?.[menuFields?.[0]?.id] ?? "Need to add" : <div dangerouslySetInnerHTML={{ __html: editedData?.[menuFields?.[0]?.id] || "Need to add" }} />
                )}
            </Card>
        </div>
    );
};

export default RichTextForm;
