import React from 'react'
import { IoIosArrowBack } from "react-icons/io";


const ArrowIcon = ({ className, size = 25, onClick = () => { } }) => {
    return (
        <IoIosArrowBack onClick={() => onClick()} className={className} size={size} />
    )
}

export default ArrowIcon