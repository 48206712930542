import {
  Button,
  Card,
  Col,
  DatePicker,
  List,
  Row,
  Select,
  Statistic,
} from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import React, { useEffect, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import dayjs from "dayjs";
import ProgressCard from "../../../component/common/ProgressCard";
import TaskProgressPie from "../../../component/common/TaskProgressPie";
import ProcessCard from "../../../component/common/ProcessCard";

const Dashboard = () => {
  const api = useHttp();
  const [data, setData] = useState({});
  const [pieChart, setPieChart] = useState([]);
  const [taskMatrix, setTaskMatrix] = useState([]);
  const [matrixQuery, setMatrixQuery] = useState({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [financialData, setFinancialData] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [brandList, setBrandList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const { RangePicker } = DatePicker;

  const DETAILS = [
    {
      data: [
        {
          title: "Total User Count",
          count: data?.generalStatistics?.totalUserCount || 0,
          // href: "/app/users",
        },
        {
          title: "Total Clients Count (Brand)",
          count: data?.generalStatistics?.totalBrandCount || 0,
          // href: "/app/users",
        },
        {
          title: "Total Task Count",
          count: data?.generalStatistics?.totalTaskCount || 0,
          // href: "/app/users",
        },
        {
          title: "Total Payout",
          count:
            "₹ " +
            (data?.generalStatistics?.transactionalData?.[0]?.totalPayoutCount || 0),
          // href: "/app/users",
        },
        {
          title: "Total Referral Traffic",
          count:
            data?.generalStatistics?.transactionalData?.[0]?.totalReferralCount || 0,
          // href: "/app/users",
        },
        {
          title: "Total Spin earning Amount",
          count:
            "₹ " +
            (data?.generalStatistics?.transactionalData
              ?.[0]?.totalSpinRewardAmount || 0),
          // href: "/app/users",
        },
        {
          title: "Total Reffrals Amount ",
          count:
            "₹ " +
            (data?.generalStatistics?.transactionalData?.[0]?.totalReferralAmount ||
              0),
          // href: "/app/users",
        },
        {
          title: "Task Completion Rate",
          count: data?.generalStatistics?.totalSubmissions
            ? (
                (data?.generalStatistics?.totalAccepted /
                  data?.generalStatistics?.totalSubmissions) *
                100
              ).toFixed(2) + "%"
            : 0 + "%",
          // href: "/app/users",
        },
      ],
    },
  ];

  useEffect(() => {
    api.sendRequest(
      CONSTANTS.API.Analytics.getGeneral,
      (res) => {
        setData(res?.data);
        setFinancialData([
          {
            key: "Total User Earnings",
            value: res?.data?.financialData?.[0]?.totalEarnings?.toFixed() || 0,
          },
          {
            key: "Total User Wallet Balance ",
            value:
              res?.data?.financialData?.[0]?.totalPayoutWalletBalance?.toFixed() || 0,
          },
          {
            key: "Total Payout",
            value: res?.data?.financialData?.[0]?.totalPayout?.toFixed() || 0,
          },
          {
            key: "Average User Earning",
            value: res?.data?.averageUserEarning?.toFixed() || 0,
          },
        ]);
      },
      selectedDates
    );
  }, [selectedDates]);

  useEffect(() => {
    api.sendRequest(
      CONSTANTS.API.Analytics.getTaskMatrix,
      (res) => {
        setTaskMatrix(res);
        const { taskSubmissions } = res;
        const pieChartData = [
          {
            type: "Total Pending",
            value: taskSubmissions.totalPending,
            color: "#FA8C16",
          },
          {
            type: "Total In-Progress",
            value: taskSubmissions.totalInProgress,
            color: "#7D8995	",
          },
          {
            type: "Total Accepted",
            value: taskSubmissions.totalAccepted,
            color: "#28A745	",
          },
          {
            type: "Total Rework",
            value: taskSubmissions.totalRework,
            color: "#722ED1",
          },
          {
            type: "Total Rejected",
            value: taskSubmissions.totalRejected,
            color: "#DC3545",
          },
          {
            type: "Total Submissions",
            value: taskSubmissions.totalSubmissions,
            color: "#1677ff",
          },
        ];
        setPieChart(pieChartData);
      },
      matrixQuery
    );
  }, [matrixQuery]);

  useEffect(() => {
    api.sendRequest(CONSTANTS.API.Analytics.getBrandForAnalytics, (res) => {
      const formateData = res?.data?.rows?.map((item) => {
        return {
          id: item?.id,
          label: item?.name,
          value: item?.id,
        };
      });
      setBrandList(formateData);
    });
  }, []);

  useEffect(() => {
    const payload = {
      ...(matrixQuery?.brandId && { brandId: matrixQuery.brandId }), // Include brandId if available
    };
    api.sendRequest(
      CONSTANTS.API.Analytics.getTaskForAnalytics,
      (res) => {
        const formateData = res?.data?.rows?.map((item) => {
          return {
            id: item?.id,
            label: item?.name,
            value: item?.id,
          };
        });
        setTaskList(formateData);
      },
      payload
    );
  }, [matrixQuery?.brandId]);

  useEffect(() => {
    const payload = {
      ...(matrixQuery?.brandId && { brandId: matrixQuery.brandId }), // Include brandId if available
      ...(matrixQuery?.taskId && { taskId: matrixQuery.taskId }), // Include taskId if available
    };
    api.sendRequest(
      CONSTANTS.API.Analytics.getCityForAnalytics,
      (res) => {
        const formateData = res?.data?.map((item) => {
          return {
            id: item?.city,
            label: item?.city,
            value: item?.city,
          };
        });
        setCityList(formateData);
      },
      payload
    );
  }, [matrixQuery?.brandId, matrixQuery?.taskId]);

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedDates({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      setSelectedDates({
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      });
    }
  };

  const onChangeMatrix = (dates, dateStrings) => {
    if (dates) {
      setMatrixQuery((pre) => ({
        ...pre,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      }));
    }
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const downloadPdf = async () => {
    var downloadBtn = document.getElementById("downloadbtn");
    if (downloadBtn) downloadBtn.style.display = "none";

    var element = document.querySelector("#Customer_report_page");
    await html2canvas(element).then((canvas) => {
      var doc = new jsPDF("portrait", "mm", "a4");

      var pageWidth = doc.internal.pageSize.getWidth();
      var pageHeight = doc.internal.pageSize.getHeight();

      var imgWidth = pageWidth;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;

      if (imgHeight > pageHeight) {
        imgHeight = pageHeight;
        imgWidth = (canvas.width * imgHeight) / canvas.height;
      }

      var xOffset = (pageWidth - imgWidth) / 2;
      var yOffset = (pageHeight - imgHeight) / 2;

      doc.setFillColor("#f5f5f5");
      doc.rect(0, 0, pageWidth, pageHeight, "F");

      doc.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        xOffset,
        yOffset,
        imgWidth,
        imgHeight
      );

      doc.save("Report.pdf");
    });

    if (downloadBtn) downloadBtn.style.display = "block";
  };

  return (
    <div id="Customer_report_page" className="bg-[#f5f5f5] pb-7">
      <Row>
        <Col span={24}>
          <Card
            bodyStyle={{
              width: "100%",
              padding: "18px 24px",
            }}
            className="w-[100%] mt-6"
          >
            <div className="flex justify-between">
              <div className="text-2xl font-medium">Data Analytics</div>
              <div className="flex gap-5">
              <RangePicker
                value={[
                  dayjs(selectedDates.startDate),
                  dayjs(selectedDates.endDate),
                ]}
                onChange={onChange}
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
              />
              <Button
                type="primary"
                id="downloadbtn"
                onClick={() => downloadPdf()}
              >
                Download
              </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-6 mb-6">
        {DETAILS?.map((el) => (
          <>
            {el?.data?.map((ell) => (
              <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                <Card
                  hoverable={ell?.href}
                  size="small"
                  className={`p-5 ${ell?.href ? "cursor-pointer" : ""}`}
                >
                  <Statistic title={ell?.title} value={ell?.count} />
                </Card>
              </Col>
            ))}
          </>
        ))}
      </Row>
      <Row gutter={[16, 16]} className="mt-6 mb-6">
        <Col span={24} md={24}>
          <div className="bg-white rounded-lg pb-10 pt-5">
            <div className="pb-7 text-center text-gray-600 text-xl">
              User Analytics
            </div>
            <div className="">
              <ProcessCard />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-6 mb-6">
        <Col span={24} md={8}>
          <div className="bg-white rounded-lg pb-10 pt-5 h-[420px]">
            <ProgressCard data={data?.userAllData?.[0] || {}} type={"Age"} />
          </div>
        </Col>
        <Col span={24} md={8} className="h-20">
          <div className="bg-white rounded-lg pb-10 pt-5 h-[420px]">
            <ProgressCard data={data?.userAllData?.[0] || {}} type={"Gender"} />
          </div>
        </Col>
        <Col span={24} md={8} className="h-20">
          <div className="bg-white rounded-lg pb-10 pt-5 h-[420px]">
            <ProgressCard data={data?.cityWiseData || []} type={"Location"} />
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col span={24}>
          <Card
            bodyStyle={{
              width: "100%",
              padding: "18px 24px",
            }}
            className="w-[100%]"
          >
            <div className="flex justify-between">
              <div className="text-2xl font-medium">Task Progress</div>
              <div className="flex gap-4">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  // mode="multiple"
                  placeholder="Brand"
                  style={{
                    width: "100%",
                  }}
                  value={matrixQuery?.brandId}
                  onChange={(value) => {
                    if (value) {
                      setMatrixQuery((prev) => ({ ...prev, brandId: value }));
                      // setSelectedFilter((prev) => prev + 1);
                    } else {
                      setMatrixQuery((prev) => {
                        const oldQuery = { ...prev };
                        delete oldQuery?.brandId;
                        // setSelectedFilter((prev) => prev - 1);
                        return {
                          ...oldQuery,
                        };
                      });
                    }
                  }}
                  options={brandList}
                  className=" max-w-[140px]"
                  maxTagCount={1}
                />
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  // mode="multiple"
                  placeholder="Task"
                  style={{
                    width: "100%",
                  }}
                  value={matrixQuery?.taskId}
                  onChange={(value) => {
                    if (value) {
                      setMatrixQuery((prev) => ({ ...prev, taskId: value }));
                      // setSelectedFilter((prev) => prev + 1);
                    } else {
                      setMatrixQuery((prev) => {
                        const oldQuery = { ...prev };
                        delete oldQuery?.taskId;
                        // setSelectedFilter((prev) => prev - 1);
                        return {
                          ...oldQuery,
                        };
                      });
                    }
                  }}
                  options={taskList}
                  className=" max-w-[140px]"
                  maxTagCount={1}
                />
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  // mode="multiple"
                  placeholder="City"
                  style={{
                    width: "100%",
                  }}
                  value={matrixQuery?.city}
                  onChange={(value) => {
                    if (value) {
                      setMatrixQuery((prev) => ({ ...prev, city: value }));
                    } else {
                      setMatrixQuery((prev) => {
                        const oldQuery = { ...prev };
                        delete oldQuery?.city;
                        return {
                          ...oldQuery,
                        };
                      });
                    }
                  }}
                  options={cityList}
                  className=" max-w-[140px]"
                  maxTagCount={1}
                />
                <RangePicker
                  value={[
                    dayjs(matrixQuery.startDate),
                    dayjs(matrixQuery.endDate),
                  ]}
                  onChange={onChangeMatrix}
                  format="YYYY-MM-DD"
                  style={{ minWidth: 220 }}
                  disabledDate={disabledDate}
                  allowClear={false}
                />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24} md={16} sm={24}>
          <div className="bg-white rounded-lg h-[450px]">
            <div className="h-[400px] p-5">
              <TaskProgressPie chartData={pieChart} />
            </div>
          </div>
        </Col>
        <Col span={24} md={8} sm={24} className="!h-[450px]">
          <Row gutter={[0, 12]} className="h-full">
            <Col span={24} className="bg-white rounded-lg flex items-center">
              <Statistic
                title={"Number of Active Tasks"}
                value={taskMatrix?.totalActiveTaskData}
                className="px-5"
              />
            </Col>
            <Col span={24} className="bg-white rounded-lg flex items-center">
              <Statistic
                title={"Total Submissions"}
                value={taskMatrix?.taskSubmissions?.totalSubmissions}
                className="px-5"
              />
            </Col>
            <Col span={24} className="bg-white rounded-lg flex items-center">
              <Statistic
                title={"Task Completion Rate"}
                value={
                  taskMatrix?.taskSubmissions?.totalSubmissions
                    ? (
                        (taskMatrix?.taskSubmissions?.totalAccepted /
                          taskMatrix?.taskSubmissions?.totalSubmissions) *
                        100
                      ).toFixed(2) + "%"
                    : 0 + "%"
                }
                className="px-5"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-6 mb-6">
        <Col span={24} md={12}>
          <div className="bg-white rounded-lg pb-10 pt-5 h-[500px]">
            <div className="pb-12 text-center text-gray-600 text-xl">
              Popular Task Categories
            </div>
            <ProgressCard
              data={data?.popularCategoryData || []}
              type={"Category"}
            />
          </div>
        </Col>
        <Col span={24} md={12}>
          <div className="bg-white rounded-lg pb-10 pt-5 h-[500px]">
            <div className="pb-7 text-center text-gray-600 text-xl">
              Financial
              {financialData?.length && (
                <List
                  size="large"
                  // bordered
                  className="m-12"
                  dataSource={financialData}
                  renderItem={(item) => (
                    <List.Item className="flex justify-between">
                      <p className="text-lg text-gray-600 font-semibold">
                        {item.key}
                      </p>
                      <p className="text-lg">₹ {item?.value}</p>
                    </List.Item>
                  )}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
