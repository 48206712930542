import React, { useState } from "react";
import FormFields from "../../../component/common/FormFields";
import Papa from "papaparse";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Select, Upload, message } from "antd";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { TASK_APPROVAL_FORMS, apiGenerator } from "../../../util/functions";
import Label from "../../../component/common/Label";

const action = ["Accepted", "Reject", "Rework"];

function BulkAction({ bulkAction, setBulkAction, api, id }) {
  const [activeForm, setActiveForm] = useState(null);
  const [numberList, setNumberList] = useState([]);
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!activeForm) {
          message.error("Please select action");
          return;
        } else if (!numberList.length) {
          message.error("Please upload a valid CSV file with Mobile Numbers.");
          return;
        }
        const payload = {
          ...values,
          status: activeForm,
          mobileNumbers: numberList,
        };
        const BULK_UPDATE_API = apiGenerator(
          CONSTANTS.API.UserTask.bulkUpdate,
          {
            taskId: id,
          }
        );
        api.sendRequest(
          BULK_UPDATE_API,
          (res) => {
            if (!res) return;
            setBulkAction((pre) => !pre);
          },
          payload,
          "Bulk Action approved"
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleFileUpload = (file) => {
    if (file instanceof Blob) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            const mobileNumbers = results.data
              .map((row) => row["Mobile Number"])
              .filter(Boolean);
            setNumberList(mobileNumbers);
          },
          error: (error) => {
            message.error("Error parsing CSV file");
          },
        });
      };
      reader.readAsText(file);
    } else {
      message.error("File is not a valid Blob");
    }
  };

  const uploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error("You can only upload CSV files!");
        return Upload.LIST_IGNORE;
      }
      handleFileUpload(file); // Process the file immediately
      return false; // Prevent automatic upload
    },
    maxCount: 1, // Limit to single file upload
  };

  const footer = {
    footer: [
      <Button
        key="submit"
        style={{
          borderRadius: "4px",
          height: "40px",
          width: "100px",
        }}
        type="primary"
        onClick={handleFormSubmit}
        // loading={loading}
      >
        Submit
      </Button>,
    ],
  };
  return (
    <Modal
      open={bulkAction}
      title={<p className="form-modal-head mb25">Bulk Action</p>}
      okText="Submit"
      width={700}
      {...footer}
      cancelButtonProps={{ style: { display: "none" } }}
      className="form-modal-title"
      onCancel={() => {
        setBulkAction((pre) => !pre);
        setActiveForm(null);
      }}
      onOk={handleFormSubmit}
    >
      <Row className="mt-5 mb-2" gutter={[16, 16]}>
        <Col span={12}>
          <Label required={true}>Action</Label>
          <Select
            className="w-[120px]"
            placeholder={"Select"}
            value={activeForm}
            onClear={() => {
              setActiveForm(null);
            }}
            onChange={(value) => {
              setActiveForm(value);
            }}
          >
            {action.map((item) => (
              <Select.Option key={item}>{item}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <Label required={true}>File</Label>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Col>
      </Row>
      {activeForm && (
        <FormFields
          menuFields={
            CONSTANTS.FORM_FIELD[TASK_APPROVAL_FORMS[activeForm]?.form]
          }
          form={form}
          disabled={false}
        />
      )}
    </Modal>
  );
}

export default BulkAction;
