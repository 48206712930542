import { Button, Card, Col, Form, notification, Popconfirm, Row } from "antd";
import React from "react";
import FormFields from "../../../../component/common/FormFields";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { IoIosNotificationsOutline } from "react-icons/io";
import useHttp from "../../../../hooks/use-http";

function Notification() {
  const api = useHttp();
  const [form] = Form.useForm();

  const handleFormSubmit = (type) => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        api.sendRequest(
          CONSTANTS.API.notification.send,
          (res) => {
            form.resetFields();
            notification.success({ message: "Notification send successfully" });
          },
          {
            ...values,
            topic:
              window?.location?.hostname === "admin.teektask.com"
                ? "prod_general"
                : window?.location?.hostname ===
                  "https://test-admin.teektask.com/"
                ? "stag_general"
                : "dev_general",
            type: "general",
          }
        );
      })
      ?.catch(() => {});
  };

  return (
    <div className="bg-[#f0f2f5] p-14">
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Card title="Generel Notification" className="h-[400px]">
            <FormFields
              formFields={CONSTANTS.FORM_FIELD.GENEREL_NOTIFICATION}
              form={form}
            />
            <Popconfirm
              description={"Are you sure?"}
              onConfirm={() => handleFormSubmit("general")}
            >
              <Button
                type="primary mt-5 mb-7 flex"
                icon={<IoIosNotificationsOutline size={25} />}
                size={"large"}
              >
                Send
              </Button>
            </Popconfirm>
          </Card>
        </Col>
        {/* <Col span={12}>
          <Card title="Personal Notification" className="h-[400px]">
            <FormFields
              formFields={CONSTANTS.FORM_FIELD.USER_NOTIFICATION}
              form={form}
            />
            <Popconfirm
              description={"Are you sure?"}
              onConfirm={() => handleFormSubmit("user")}
            >
              <Button
                type="primary mt-5 mb-7"
                icon={<IoIosNotificationsOutline />}
                size={"large"}
              >
                Download
              </Button>
            </Popconfirm>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
}

export default Notification;
