import {
  Card,
  Col,
  Image,
  Modal,
  Row,
  Select,
  Switch,
  Tabs,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import {
  apiGenerator,
  convertUTCToLocal,
  PERMISSION,
} from "../../../util/functions";
import CONSTANTS, {
  RenderEditButton,
  RenderToggleButton,
} from "../../../util/constant/CONSTANTS";
import ArrowIcon from "../../../component/common/ArrowIcon";
import { TfiWallet } from "react-icons/tfi";
import { EyeOutlined } from "@ant-design/icons";
import Transaction from "./tabs/Transaction";
import Task from "./tabs/Task";
import dummy_img from "../../../asset/image/dummy-avatar.jpg";
import WorkProof from "../../../component/taskApproval/WorkProof";
import AffiliateHistory from "./tabs/AffiliateHistory";

const UserDetail = ({ admindetails, permissionId }) => {
  const [docList, setDocList] = useState(null);
  const [userData, setUserData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const api = useHttp();
  const { id } = useParams();
  const navigate = useNavigate();

  const menus = {
    transaction: <Transaction id={id} />,
    task: <Task id={id} />,
    affiliateHistory: <AffiliateHistory id={id} />,
  };
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  useEffect(() => {
    const USER_DETAIL_API = apiGenerator(CONSTANTS.API.user.getOne, { id });

    api.sendRequest(USER_DETAIL_API, (res) => {
      setUserData(res?.data);
    });
  }, [refresh]);
  return (
    <>
      <div className="my-5">
        <Card>
          <div className="flex items-center gap-2">
            <ArrowIcon
              onClick={() => navigate(-1)}
              className={"cursor-pointer"}
              size={27}
            />
            <p className="font-medium text-2xl">User Personal Details </p>
          </div>

          <Row className="my-3">
            <Col span={8}>
              {/* Profile Pic */}
              <div>
                <Image
                  className="rounded-full"
                  width={60}
                  height={60}
                  src={userData?.image || dummy_img}
                  alt="user profile"
                />
              </div>
              <div className="my-5 font-medium">
                <span className="text-[#A8B0B8]">User Name :&nbsp;</span>
                {userData?.name}
              </div>
              <div className="my-5 font-medium">
                <span className="text-[#A8B0B8]">Mobile No. :&nbsp;</span>
                {userData?.countryCode} {userData?.mobile}
              </div>
              <div className="my-5 font-medium">
                <span className="text-[#A8B0B8]">Address Line 1 :&nbsp;</span>
                {userData?.addressLine1}
              </div>
              <div className="my-5 font-medium">
                <span className="text-[#A8B0B8]">Address Line 2 :&nbsp;</span>
                {userData?.addressLine2}
              </div>
            </Col>
            <Col span={8}>
              <div className=" font-medium">
                <span className="text-[#A8B0B8]">Referral Code :&nbsp;</span>
                {userData?.referenceCode}
              </div>
              <div className="my-5 font-medium flex  items-center gap-2">
                Affiliate Share
                {RenderToggleButton({
                  checked: !userData?.isAffiliateEnable,
                  id: userData?.id,
                  size: "small",
                  isDisable: PERMISSION[accessPermission] === "READ",
                  onClick: (id, val) => {
                    const UpdateAPIEnd = { ...CONSTANTS?.API.user.update };
                    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                    api.sendRequest(
                      UpdateAPIEnd,
                      () => {
                        setRefresh((prev) => !prev);
                      },
                      { isAffiliateEnable: val },
                      `Affiliate ${
                        val ? "Activate" : "Deactivate"
                      } Successfully`
                    );
                  },
                })}
              </div>
              <div className="my-5 font-medium">
                <span className="text-[#A8B0B8]">Gender :&nbsp;</span>
                {userData?.gender}
              </div>
              <div className="my-5 font-medium">
                <span className="text-[#A8B0B8]">Email ID :&nbsp;</span>
                {userData?.email}
              </div>
            </Col>
            <Col span={8}>
              <div className="my-5 font-medium flex  items-center gap-2">
                Block User
                {RenderToggleButton({
                  checked: !userData?.isBlocked,
                  id: userData?.id,
                  isDisable: PERMISSION[accessPermission] === "READ",
                  size: "small",
                  onClick: (id, val) => {
                    const UpdateAPIEnd = { ...CONSTANTS?.API.user.update };
                    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                    api.sendRequest(
                      UpdateAPIEnd,
                      () => {
                        setRefresh((prev) => !prev);
                      },
                      { isBlocked: val },
                      `User ${val ? "Blocked" : "Unblock"} Successfully`
                    );
                  },
                })}
              </div>
              <div className="my-5 font-medium flex  items-center gap-2">
                Task Auto Approval
                {RenderToggleButton({
                  checked: !userData?.isAutoApproval,
                  id: userData?.id,
                  isDisable: PERMISSION[accessPermission] === "READ",
                  size: "small",
                  onClick: (id, val) => {
                    const UpdateAPIEnd = { ...CONSTANTS?.API.user.update };
                    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                    api.sendRequest(
                      UpdateAPIEnd,
                      () => {
                        setRefresh((prev) => !prev);
                      },
                      { isAutoApproval: val },
                      `Auto Approval ${val ? "Enable" : "Disable"} Successfully`
                    );
                  },
                })}
              </div>
              <div className="font-medium">
                <span className="text-[#A8B0B8]">Date od Birth :&nbsp;</span>
                {convertUTCToLocal(userData?.DOB, "DD/MM/YYYY")}
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col span={6}>
              <div className="font-medium">
                <span className="text-[#A8B0B8]">PinCode :&nbsp;</span>
                {userData?.pinCode}
              </div>
            </Col>
            <Col span={6}>
              <div className="font-medium">
                <span className="text-[#A8B0B8]">City :&nbsp;</span>
                {userData?.city}
              </div>
            </Col>
            <Col span={6}>
              <div className="font-medium">
                <span className="text-[#A8B0B8]">State :&nbsp;</span>
                {userData?.state}
              </div>
            </Col>
            <Col span={6}>
              <div className="font-medium">
                <span className="text-[#A8B0B8]">Country :&nbsp;</span>
                {userData?.country}
              </div>
            </Col>
          </Row>
        </Card>
        <Row gutter={[24, 16]} className="my-5">
          <Col span={12}>
            <Card>
              <div className="flex justify-between items-center">
                <p className="font-medium text-2xl">Wallet Balance</p>
                <div className="font-medium text-[#A8B0B8]">
                  Available Spin :&nbsp;
                  <span className="text-black text-2xl">
                    {userData?.spinAvailable || 0}
                  </span>
                </div>
              </div>
              <div className="mt-10 flex justify-between items-center">
                <TfiWallet color="#007BFF" size={40} />
                <p className="font-medium text-xl">
                  ₹
                  <span className="text-4xl">
                    &nbsp;{userData?.walletBalance || 0}
                  </span>
                </p>
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <div className="flex justify-between items-center">
                <p className="font-medium text-2xl">Identity & UPI</p>
                <div className="flex items-center gap-3">
                  {/* <div>
                                    {RenderEditButton({
                                        id: userData?.id,
                                        onClick: () => {
                                        },
                                    })}
                                </div> */}
                  <div className="font-medium flex  items-center gap-2">
                    <Select
                      value={userData?.isGovermentIdVerified}
                      disabled={PERMISSION[accessPermission] === "READ"}
                      style={{
                        width: 125,
                      }}
                      onChange={(val) => {
                        const UpdateAPIEnd = { ...CONSTANTS?.API.user.update };
                        UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                        api.sendRequest(
                          UpdateAPIEnd,
                          () => {
                            setRefresh((prev) => !prev);
                          },
                          { isGovermentIdVerified: val },
                          `Verification Status Updated Successfully`
                        );
                      }}
                      options={[
                        {
                          value: "Not Verified",
                          label: "Not Verified",
                        },
                        {
                          value: "Pending",
                          label: "Pending",
                        },
                        {
                          value: "Verified",
                          label: "Verified",
                        },
                        {
                          value: "Failed",
                          label: "Failed",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="my-5 flex justify-between items-center">
                <div className="font-medium text-[#A8B0B8]">
                  Aadhar Card :&nbsp;
                  <span className="text-black ">
                    {userData?.adharCardNumber || "N/A"}
                  </span>
                </div>
                <div className="font-medium text-[#A8B0B8]">
                  PAN Card :&nbsp;
                  <span className="text-black ">
                    {userData?.panCardNumber || "N/A"}
                  </span>
                </div>
              </div>
              <div className=" flex justify-between items-center">
                <div className="font-medium text-[#A8B0B8]">
                  UPI ID :&nbsp;
                  <span className="text-black l">{userData?.UPI || "N/A"}</span>
                </div>
                <div className="cursor-pointer">
                  <EyeOutlined
                    className={`text-xl`}
                    onClick={() => {
                      const documentList = [
                        userData?.idCard,
                        userData?.aadharCard,
                        userData?.aadharCardBack,
                        userData?.panCard,
                      ].filter((url) => url !== null);
                      documentList?.length
                        ? setDocList(documentList)
                        : notification.warning({
                            message: "Data Not Found",
                            duration: "3",
                          });
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Card className="my-5">
          <Tabs
            className="p-0 m-0"
            size="middle"
            type="card"
            items={CONSTANTS.TAB_MENU.USER.map((ele) => {
              return {
                label: `${ele.Label}`,
                key: `${ele.id}`,
                children: menus[ele?.id],
              };
            })}
          />
        </Card>
      </div>
      <Modal
        title={"View Documents"}
        centered
        open={docList}
        onCancel={() => {
          setDocList(null);
        }}
        width={800}
        footer={null}
      >
        <WorkProof MultipleImages={docList} />
        <div className="flex justify-end mt-5 items-center">
          Verification
          <Switch
            size={"small"}
            className="ms-2"
            onChange={(val) => {
              const UpdateAPIEnd = {
                ...CONSTANTS?.API.user.update,
              };
              UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${userData?.id}`;
              api.sendRequest(
                UpdateAPIEnd,
                () => {
                  setRefresh((prev) => !prev);
                },
                { isGovermentIdVerified: val ? "Verified" : "Not Verified" },
                `Verification Updated Successfully`
              );
            }}
            defaultChecked={userData?.isGovermentIdVerified === "Verified"}
            disabled={PERMISSION[accessPermission] === "READ"}
          />
        </div>
      </Modal>
    </>
  );
};

export default UserDetail;
