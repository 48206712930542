import React, { memo } from "react";
import { Row } from "antd";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";

const AffiliateHistory = ({ id }) => {
  return (
    <>
      <>
        <Row>
          <CRUDComponent
            GET={{
              API: CONSTANTS.API.affiliate.getAll,
              extraQuery: { userId: id },
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.no,
                    ...data?.user,
                    taskName: data?.task?.name,
                    amount: data?.task?.completionAmount,
                  };
                });
              },
              column: CONSTANTS.TABLE.AFFILIATE_HISTORY,
            }}
            isSearch
          />
        </Row>
      </>
    </>
  );
};

export default memo(AffiliateHistory);
