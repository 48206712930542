import { Card, Row } from "antd";
import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { convertUTCToLocal, PERMISSION } from "../../../util/functions";
import { useNavigate } from "react-router-dom";
import { userExportData } from "../../../util/exportData";

const User = ({ admindetails, permissionId }) => {
  const navigate = useNavigate();
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>User List</Heading>
          <Row>
            <CRUDComponent
              title="User"
              exportData={userExportData}
              accessPermission={accessPermission}
              GET={{
                API: CONSTANTS.API.user.getAll,
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      no: `U${data?.id.toString().padStart(7, "0")}`,
                      view: {
                        id: data?.id,
                        onClick: (id) => {
                          navigate(`/app/user/${id}`);
                        },
                      },
                      // dob: convertUTCToLocal(data?.DOB, "DD-MM-YYYY"),
                      toggle: {
                        checked: !data?.isBlocked,
                        id: data?.id,
                        isDisable: PERMISSION[accessPermission] === "READ",
                        onClick: (id, val) => {
                          const UpdateAPIEnd = {
                            ...CONSTANTS?.API.user.update,
                          };
                          UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                          API.sendRequest(
                            UpdateAPIEnd,
                            () => {
                              Setrefresh((prev) => !prev);
                            },
                            { isBlocked: val },
                            `User ${val ? "Blocked" : "Unblock"} Successfully`
                          );
                        },
                      },
                      identify: (
                        <CheckCircleOutlined
                          className={`text-3xl ${
                            data?.isGovermentIdVerified === "Verified"
                              ? "text-[#007BFF]"
                              : data?.isGovermentIdVerified === "Failed"
                              ? "text-red-500"
                              : "text-slate-500"
                          }`}
                        />
                      ),
                      isAffiliateD: data?.isAffiliateEnable ? "Yes" : "No",
                      isBlockedD: data?.isBlocked ? "Yes" : "No",
                      isIdentifyD: data?.isGovermentIdVerified,
                      affiliate: {
                        checked: !data?.isAffiliateEnable,
                        id: data?.id,
                        isDisable: PERMISSION[accessPermission] === "READ",
                        onClick: (id, val) => {
                          const UpdateAPIEnd = {
                            ...CONSTANTS?.API.user.update,
                          };
                          UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                          API.sendRequest(
                            UpdateAPIEnd,
                            () => {
                              Setrefresh((prev) => !prev);
                            },
                            { isAffiliateEnable: val },
                            `Affiliate ${
                              val ? "Activate" : "Deactivate"
                            } Successfully`
                          );
                        },
                      },
                    };
                  });
                },
                column: CONSTANTS.TABLE.USERS,
              }}
              isSearch
              UPDATE={{
                API: CONSTANTS.API.user.update,
                isFormData: true,
                message: "Edit User successfully",
                modaltitle: "Edit User",
                modalFields: CONSTANTS.FORM_FIELD.EDIT_USER_MODAL,
                // payloadModifier: (res) => res,
              }}
            />
          </Row>
        </Card>
      </div>
    </>
  );
};

export default User;
