import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import { apiGenerator } from "../../../util/functions";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import CommonTaskDetail from "../../../component/common/CommonTaskDetail";
const BrandTaskDetail = ({ admindetails, permissionId }) => {
  const [taskData, setTaskData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const api = useHttp();
  const { id } = useParams();
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];

  useEffect(() => {
    const TASK_DETAIL_API = apiGenerator(CONSTANTS.API.Task_Management.getOne, {
      id,
    });
    api.sendRequest(TASK_DETAIL_API, (res) => {
      setTaskData({
        ...res?.data,
        isRegion: res?.data?.isRegionBased,
      });
    });
  }, [refresh]);
  return (
    <>
      <CommonTaskDetail
        accessPermission={accessPermission}
        api={api}
        setRefresh={setRefresh}
        {...taskData}
        type="brand"
      />
    </>
  );
};

export default BrandTaskDetail;
