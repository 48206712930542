import React, { memo } from "react";
import { Pie } from "@ant-design/plots";
import { Empty } from "antd";

const TaskProgressPie = ({ chartData = [] }) => {
  const isAllZero = !chartData.some((item) => item.value > 0);
  const data = isAllZero ? [{value: 0, type: "No Data", color: "#7D8995"}] : chartData.filter((item) => item.value > 0);
  const config = {
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: isAllZero
      ? false
      : {
          type: "spider",
          content: ({ value, type }) => `${type}\n ${value}`,
        },
    color: ({ type }) => {
      const item = data.find((d) => d.type === type);
      return item?.color;
    },
    legend: {
      position: "right",
      layout: "vertical",
      itemName: {
        formatter: (text) => {
          const child = chartData.find((d) => d.type === text); // Use original data for legend
          return `${text}: ${child?.value}`;
        },
      },
      custom: true, // Enable custom legend
      items: chartData.map((item) => ({
        name: item.type,
        value: item.value,
        marker: {
          symbol: "circle",
          style: {
            fill: item.color || "#000", // Use the color defined in the data or fallback to black
          },
        },
      })),
    },
    // interactions: [
    //   {
    //     type: "element-active",
    //   },
    // ],
  };
  if (!chartData?.length)
    return (
      <div className=" h-96 flex items-center justify-center w-full">
        <Empty />
      </div>
    );
  return <Pie {...config} />;
};

export default memo(TaskProgressPie);
