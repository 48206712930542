import React from "react";

import { Line } from "@ant-design/plots";
import { Empty, Spin } from "antd";
import CONSTANTS from "../../util/constant/CONSTANTS";

export function LineChart({
  data = [],
  isLoading = false,
}) {
  const config = {
    data,
    xField: "date",
    yField: "userCount",
    seriesField: "userCount",
    legend: false,
    smooth: true,
    lineStyle: ({ name }) => {
      return {
        opacity: 1,
      };
    },
  };
  if (isLoading) {
    return (
      <div className=" h-96  w-full">
        <Spin className="mt-48" tip="Loading..." size="large">
          <div className="content" />
        </Spin>
      </div>
    );
  }

  if (data?.length === 0) {
    return (
      <div className=" h-96 flex items-center justify-center w-full">
        <Empty />
      </div>
    );
  }
  return <Line {...config} />;
}

export default LineChart;
