import {
  Button,
  Card,
  Modal,
  Radio,
  Row,
  Select,
  Tabs,
  notification,
} from "antd";
import React, { memo, useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import CRUDComponent from "../../../component/common/CRUD-Component";
import Heading from "../../../component/common/Heading";
import { PlusCircleOutlined } from "@ant-design/icons";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { EditOutlined } from "@ant-design/icons";
import {
  PERMISSION,
  TASK_APPROVAL_FORMS,
  TASK_APPROVAL_OPTION,
  apiGenerator,
} from "../../../util/functions";
import ViewTask from "../../../component/taskApproval/ViewTask";
import WorkProof from "../../../component/taskApproval/WorkProof";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import { taskApprovalExportData } from "../../../util/exportData";
import BulkAction from "./BulkAction";

const TaskApproval = ({ admindetails, permissionId }) => {
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  const api = useHttp();
  const { id } = useParams();
  const status = ["Accepted", "Rework", "Reject"];
  const [openModal, setOpenModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const [bulkAction, setBulkAction] = useState(false);
  const detailsModal = {
    taskDetails: {
      title: "Task Details",
      child: <ViewTask data={modalData} />,
      width: 900,
    },
    workProof: {
      title: "Proof of Work",
      child: <WorkProof MultipleImages={modalData} />,
      width: 800,
    },
  };

  const OnFormSubmitBtn = (res) => {
    const payload = { ...res };
    payload["status"] = activeForm?.form;
    api.sendRequest(
      apiGenerator(CONSTANTS.API.UserTask.updateModal, {
        dataId: activeForm?.id,
      }),
      (res) => {
        activeForm?.setRefresh((pr) => !pr);
        setActiveForm(null);
      },
      payload,
      "Data updated successfully"
    );
  };

  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Task Approval List</Heading>
          <Row className="flex justify-between items-baseline">
            <Radio.Group
              value={taskStatus}
              onChange={(e) => setTaskStatus(e.target.value)}
              className="mt-6"
            >
              <Radio.Button value={null}>All</Radio.Button>
              {status?.map((st) => (
                <Radio.Button value={st}>{st}</Radio.Button>
              ))}
            </Radio.Group>
            {id && (
              <div className="flex gap-3">
                  <a href="/bulk-action.csv" className="flex gap-1 text-sm items-end">
                  Sample file <FaCloudDownloadAlt size={20} />
                  </a>
                <Button
                  type="primary"
                  className="flex"
                  icon={<PlusCircleOutlined className="text-xl" />}
                  onClick={() => setBulkAction((pre) => !pre)}
                >
                  Bulk Action
                </Button>
              </div>
            )}
          </Row>
          <Row>
            <CRUDComponent
              title="TaskApproval"
              exportData={taskApprovalExportData}
              // accessPermission={accessPermission}
              GET={{
                API: CONSTANTS.API.UserTask.getAll,
                extraQuery: {
                  ...(taskStatus && { status: taskStatus }),
                  ...(id && { taskId: id }),
                },
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      no: data?.no,
                      image: {
                        image: data?.user?.image,
                        name: data?.user?.name,
                      },
                      action: data?.status,
                      name: data?.user?.name,
                      mobile: data?.user?.mobile,
                      taskId: data?.taskId,
                      taskDetails: {
                        id: "taskDetails",
                        onClick: (id) => {
                          if (!data?.taskId) return;
                          const GET_TASK_API = apiGenerator(
                            CONSTANTS.API.Task_Management.getOne,
                            {
                              id: data?.taskId,
                            }
                          );
                          api.sendRequest(GET_TASK_API, (res) => {
                            if (!res) return;
                            setModalData(res?.data);
                            setOpenModal(id);
                          });
                        },
                      },
                      proofOfWork: {
                        id: "workProof",
                        onClick: (id) => {
                          if (!data?.proofOfWork?.length)
                            return notification.error({
                              message: "Data not found",
                            });
                          setModalData(data?.proofOfWork);
                          setOpenModal(id);
                        },
                      },
                      status: (
                        <Select
                          className="w-28"
                          placeholder={"Select"}
                          disabled={PERMISSION[accessPermission] === "READ"}
                          value={
                            ["Accepted", "Reject", "Rework"].includes(
                              data?.status
                            )
                              ? data?.status
                              : null
                          }
                          onClear={() => {
                            setActiveForm(null);
                          }}
                          onChange={(value, f) => {
                            setActiveForm({
                              id: data?.id,
                              form: value,
                              setRefresh: Setrefresh,
                            });
                            console.log(value);
                          }}
                        >
                          {TASK_APPROVAL_OPTION.map((item) => (
                            <Select.Option key={`${item.value}`}>
                              {item.label ? item.label : item.value}
                            </Select.Option>
                          ))}
                        </Select>
                      ),
                    };
                  });
                },
                column: CONSTANTS.TABLE.TASKAPPROVAL,
              }}
              isSearch
              UPDATE={{
                API: CONSTANTS.API.UserTask.update,
                message: "Message Updated successfully",
                modaltitle: "Message",
                modalFields: CONSTANTS?.FORM_FIELD?.TASK_APPROVAL_MESSAGE,
                // payloadModifier: (res) => res,
              }}
            />
          </Row>
        </Card>
      </div>
      <Modal
        title={detailsModal[openModal]?.title}
        centered
        open={openModal}
        onCancel={() => {
          setOpenModal(null);
          setModalData(null);
        }}
        width={detailsModal[openModal]?.width}
        footer={null}
      >
        {openModal && detailsModal[openModal]?.child}
      </Modal>
      <ModalFormCreator
        loading={api.isLoading}
        open={activeForm}
        onCreate={OnFormSubmitBtn}
        onCancel={() => {
          setActiveForm((pre) => !pre);
        }}
        menuFields={
          CONSTANTS.FORM_FIELD[TASK_APPROVAL_FORMS[activeForm?.form]?.form]
        }
        name={TASK_APPROVAL_FORMS[activeForm?.form]?.modaltitle}
        SubmitName={"Submit"}
      />
      {id && (
        <BulkAction
          bulkAction={bulkAction}
          setBulkAction={setBulkAction}
          api={api}
          id={id}
        />
      )}
    </>
  );
};

export default memo(TaskApproval);
