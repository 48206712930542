import { Popconfirm, Row, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineDeleteSweep } from "react-icons/md";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../util/constant/CONSTANTS";

const CRUDTable = (props) => {
  const {
    dataSource,
    Other = {},
    onChange,
    extraclass,
    isLoading = false,
    APIendpoint,
    DeleteSelectedRow = false,
    onConfirm = (value) => {
      console.log(value);
    },
    scrollAutoOff = false,
    scroll,
    setChanges = () => { },
    columns = [],
    exportData,
    setCSV
  } = props;
  const API = useHttp();
  const [selectedRows, setSelectedRows] = useState([]);

  const isDataEmpty = dataSource?.length <= 0;

  // console.log(exportData);
  useEffect(() => {
    let CSVData = [];

    if (exportData?.length) {
      if (!isDataEmpty) {
        CSVData[0] = exportData?.map((el) => el[1]);
        dataSource?.map((item, index) => {
          CSVData[index + 1] = exportData
            ?.map((el) => el[0])
            ?.map((val) => {
              if (item != null && val in item) return item[val];
              return "";
            });
          return 0;
        });
      }
    } else {
      CSVData[0] = columns?.map((el) => el.title);
      dataSource?.map((item, index) => {
        CSVData[index + 1] = columns?.map(
          (el) => item[el.dataIndex]
        );
        return 0;
      });
    };
    setCSV(CSVData)
  }, [dataSource])
  return (
    <>
      {selectedRows.length && DeleteSelectedRow ? (
        <Row className="mb20">
          <Popconfirm
            title="Sure to delete selected rows in bulk?"
            onConfirm={() => {
              const rows = [...selectedRows];
              rows?.forEach((el, i) => {
                if (CONSTANTS?.API[APIendpoint]) {
                  const DeleteAPIBulk = { ...CONSTANTS?.API[APIendpoint] };
                  DeleteAPIBulk.endpoint = DeleteAPIBulk?.endpoint?.replace(
                    ":id",
                    el
                  );
                  API.sendRequest(DeleteAPIBulk, () => {
                    if (rows?.length - 1 === i) {
                      onConfirm(rows);
                      setSelectedRows([]);
                      notification.success({
                        message: "All deleted successfully",
                      });
                    }
                  });
                }
              });
            }}
          >
            {
              <MdOutlineDeleteSweep
                style={{ color: "black" }}
                fontSize={35}
                className="ml20 cursor-pointer"
              />
            }
          </Popconfirm>
        </Row>
      ) : (
        ""
      )}
      <div>
        <Table
          rowClassName={`rows-custom ${extraclass}`}
          loading={isLoading}
          pagination={false}
          onChange={(page, filter, sort) => {
            const newFilter = [];
            for (const property in filter) {
              if (filter[property]) {
                newFilter.push([property, filter[property]]);
              }
            }
            const NewSort = {
              sort: sort?.field,
              sortBy:
                sort?.order === "ascend"
                  ? "ASC"
                  : sort?.order === "descend"
                    ? "DESC"
                    : null,
            };
            const NewChanges = {};
            if (NewSort.sortBy && NewSort?.sort !== "no") {
              NewChanges.sort = NewSort;
            }
            if (NewSort.sortBy && NewSort?.sort === "no") {
              NewChanges.sort = { ...NewSort, sort: 'id' };
            }
            if (newFilter.length) {
              NewChanges.filter = newFilter;
            }
            setChanges(NewChanges);
          }}
          // scroll={
          //   !scrollAutoOff
          //     ? {
          //         x: "80vw",
          //         y: "60vh",
          //       }
          //     : scroll
          //     ? scroll
          //     : {}
          // }
          scroll={
            scroll ? scroll : !scrollAutoOff ? { x: "80vw", y: "60vh" } : {}
          }
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          // }}
          dataSource={dataSource}
          columns={columns}
          {...Other}
        />
      </div>
    </>
  );
};
CRUDTable.defaultProps = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};
export default CRUDTable;
