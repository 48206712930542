import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, Image, Input, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import CONSTANTS, { appRoot, loginRoot } from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { getAuthToken, setAuthDetails } from "../../../util/API/authStorage";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import logo from "../../../asset/icons/loginlogo.png";
import { ROUTES } from "../../../util/Route";

const LogIn = () => {
  const formRef = React.useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(isLogin, "loh", getAuthToken());
    const isLogin = getAuthToken() !== undefined && getAuthToken() !== null;
    if (isLogin) {
      navigate(appRoot);
    }
  }, [navigate]);
  // navigate(appRoot);
  const API = useHttp();


  const validateLogin = (value) => {
    const payload = {
      email: value.email,
      password: value.password,
    };
    API.sendRequest(
      CONSTANTS.API.login,
      (res) => {
        setAuthDetails(res?.token);
        // console.log(`${appRoot}/${(res?.admin?.access?.split(',')[0])?.split('-')[0] === 'admin' ? ROUTES?.ROLE_MANAGEMENT : (res?.admin?.access?.split(',')[0])?.split('-')[0]}`);
        window.location.assign(`${appRoot}/${((res?.admin?.access?.split(',')[0])?.split('-')[0] === 'admin' || (res?.admin?.access?.split(',')[0])?.split('-')[0] === 'role') ? ROUTES?.ROLE_MANAGEMENT : (res?.admin?.access?.split(',')[0])?.split('-')[0]}`);
      },
      payload,
      "LogIn Successfully!!!"
    );
  };

  return (
    <>

      <div
        className="h-screen flex justify-center  items-center bg-[#F0F2F5]"

      >

        <div className="flex">
          {/* Logo */}
          <div className="flex items-center justify-center w-[425px]">
            <Image
              src={logo}
              alt="Teektask Logo"
              preview={false}
              width={425}
              className="p-5"
            />
          </div>
          <div className="flex items-center w-[290px] mx-[20px]">
            <div className="shadow-lg p-6 w-[100%] rounded-lg" style={{ background: "#F8F9FA", border: "none" }}>
              <p className="text-3xl mb-4  font-medium	">Login</p>

              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={validateLogin}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Email!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    type="email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={API?.isLoading}

                  // disabled
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LogIn;
