import { Empty, Progress, Row, Typography } from "antd";
import React, { memo, useEffect, useState } from "react";

const ProgressCard = ({ data, type }) => {
  const [total, setTotal] = useState(0);
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    if (type !== "Location") {
      let formateData;
      if (type === "Age") {
        formateData = [
          { key: "< 18", value: data?.ageUnder18 },
          { key: "18 - 20", value: data?.ageBetween18to20 },
          { key: "21 - 25", value: data?.ageBetween20to25 },
          { key: "25 - 31", value: data?.ageBetween25to31 },
          { key: "31 <", value: data?.ageMoreThen31 },
        ];
      } else if (type === "Category") {
        formateData = data?.map((item) => {
          return { key: item?.categoryName, value: item?.totalCount };
        });
      } else {
        formateData = [
          { key: "Male", value: data?.maleCount },
          { key: "Female", value: data?.femaleCount },
          { key: "Other", value: data?.otherCount },
        ];
      }
      const totalCount = formateData.reduce((sum, item) => sum + item.value, 0);
      setTotal(totalCount);
      setChartData(formateData);
    } else {
      let totalSum = 0;
      const formateData = data.map((item) => {
        totalSum += item.total;
        return {
          value: item.total,
          key: item.city,
        };
      });
      setChartData(formateData);
      setTotal(totalSum);
    }
  }, [data]);

  return (
    <>
      <div className="px-12 mt-2">
        <div className="flex justify-between mb-4 pe-4 font-semibold">
          <Typography.Text className="text-lg">{type}</Typography.Text>
          <Typography.Text className="text-lg flex items-baseline">
            <div className="text-[#00000073] text-sm mr-2">
              {type !== "Category" ? "Total User " : "Total Task"}
            </div>
            {total}
          </Typography.Text>
        </div>
        {chartData?.length ? (
          <div className="h-80 overflow-auto pe-2">
            {chartData?.map((process, i) => {
              return (
                <div key={`${process?.key}-${i}`} className="mb-1">
                  <Row className="justify-between items-center">
                    <Typography.Text
                      className="!m-0 !p-0 !ms-0 text-lg"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "90%",
                      }}
                    >
                      {process?.key}
                    </Typography.Text>
                    <div className="flex items-baseline">
                      <Typography.Text className="text-lg">
                        {process?.value}
                      </Typography.Text>
                      <div className="text-[#00000073] ms-1">
                        {type !== "Category" ? " User" : " Task"}
                      </div>
                      <div className="text-lg text-[#00000073] text-end !w-[60px]">
                        {process?.value
                          ? ((process?.value * 100) / total).toFixed(0)
                          : 0}
                        %
                      </div>
                    </div>
                  </Row>
                  <Progress
                    percent={
                      process?.value
                        ? ((process?.value * 100) / total).toFixed(0)
                        : 0
                    }
                    size="small"
                    showInfo={false}
                    style={{ fontSize: 14 }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mt-24">
            <Empty />
          </div>
        )}
      </div>
    </>
  );
};

export default ProgressCard;
