import React, { useState } from 'react';
import { Form, Checkbox, Button, Card, notification } from 'antd';
import ALL_ROUTES from '../../../util/Route';
import Heading from '../../../component/common/Heading';
import { useEffect } from 'react';
import useHttp from '../../../hooks/use-http';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGenerator, PERMISSION } from '../../../util/functions';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import ArrowIcon from '../../../component/common/ArrowIcon';

const CheckboxGroup = Checkbox.Group;

const childOptions = [
    { label: 'Read', value: 0 },
    { label: 'Write without delete', value: 1 },
    { label: 'All', value: 2 }
];

const AccessForm = ({ admindetails, permissionId }) => {
    const [form] = Form.useForm();
    const [parentChecks, setParentChecks] = useState([]);
    const api = useHttp()
    const { id } = useParams()
    const accessPermission = +admindetails?.access?.split(',')?.filter(ele => ele.includes(permissionId))[0]?.split('-')[2];


    const menuList = ALL_ROUTES()?.filter((ele) => {
        return ele?.path === '/app'
    })[0]?.children?.slice(1)?.filter((ele) => ele?.id)?.map(ele => ({
        label: ele?.label,
        value: ele?.id
    }))

    const navigate = useNavigate();

    const handleParentChange = (parent) => (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setParentChecks((prev) => {
                const removeExistingParent = prev.filter((item) => item?.parent !== parent)
                return [...removeExistingParent, {
                    parent,
                    access: 2
                }]
            });
            form.setFieldsValue({
                [parent]: [0, 1, 2]
            });
        } else {
            setParentChecks((prev) => prev.filter((item) => item?.parent !== parent));
            form.resetFields([parent]);
        }
    };

    const handleChildChange = (parent) => (checkedValues) => {
        // console.log(parent, checkedValues);
        if (checkedValues.includes(2)) {
            form.setFieldsValue({
                [parent]: [0, 1, 2],
                [`${parent}-parent`]: true,
            });
            setParentChecks((prev) => {
                const removeExistingParent = prev.filter((item) => item?.parent !== parent)
                return [...removeExistingParent, {
                    parent,
                    access: 2
                }]
            });
        } else if (checkedValues.includes(1)) {
            form.setFieldsValue({
                [parent]: [0, 1],
                [`${parent}-parent`]: false,
            });
            // setParentChecks((prev) => [...new Set([...prev, parent])]);
            setParentChecks((prev) => {
                const newParent = prev.filter((item) => item?.parent !== parent)
                return [...newParent, { parent, access: 1 }]
            });
        } else if (checkedValues.length === 0) {
            setParentChecks((prev) => prev.filter((item) => item.parent !== parent));
            form.resetFields([parent]);
        } else {
            form.setFieldsValue({
                [parent]: checkedValues
            });
            if (!parentChecks.includes(parent)) {
                // setParentChecks((prev) => [...prev, parent]);
                setParentChecks((prev) => {
                    const newParent = prev.filter((item) => item?.parent !== parent)
                    return [...newParent, { parent, access: 0 }]
                });
            }
        }
    };

    const setInitialValues = (accessString) => {
        const initialValues = {};
        const parentChecks = [];

        accessString.split(',').forEach((accessItem) => {
            // let [parent, accessLevel] = accessItem.split('-');
            let accessArr = accessItem.split('-');
            let parent = null;
            let accessLevel = null
            if (accessArr[0] === 'role') {
                parent = `${accessArr[0]}-${accessArr[1]}`
                accessLevel = accessArr[2]
            }
            else {
                parent = `${accessArr[0]}`
                accessLevel = accessArr[1]
            }
            const accessValue = parseInt(accessLevel, 10);

            if (accessValue === 2) {
                initialValues[parent] = [0, 1, 2];
                initialValues[`${parent}-parent`] = true;
            } else if (accessValue === 1) {
                initialValues[parent] = [0, 1];
                initialValues[`${parent}-parent`] = true;
            } else if (accessValue === 0) {
                initialValues[parent] = [0];
                initialValues[`${parent}-parent`] = true;
            }

            parentChecks.push({ parent, access: accessValue });
        });

        // console.log(initialValues, parentChecks, 'initialValues, parentChecks');

        form.setFieldsValue(initialValues);
        setParentChecks(parentChecks);
    };


    const onFinish = () => {
        const payload = parentChecks?.map(ele => `${ele?.parent}-${ele?.access}`)?.toString()

        if (payload) {
            const UPDATE_ACCESS_API = apiGenerator(CONSTANTS.API.Role_Management.update, {

            }, id)

            api.sendRequest(UPDATE_ACCESS_API, () => {

            }, { access: payload }, 'Access Updated Successfully!!!')
        }
        else {
            notification.error({
                message: 'You have to Select at least one access',
            })
        }

    };


    useEffect(() => {
        const GET_ONE_API = apiGenerator(CONSTANTS.API.Role_Management.getOne, {
            id
        });
        api.sendRequest(GET_ONE_API, (res) => {
            if (res?.data?.access) {
                setInitialValues(res?.data?.access)
            }

        })
    }, [])

    return (
        <Card className='my-5'>
            <div className='flex items-center gap-2'>
                <ArrowIcon onClick={() => navigate(-1)} className={'cursor-pointer'} size={27} />
                <p className='font-medium text-2xl'>Role and Access Form</p>
            </div>
            <Form disabled={PERMISSION[accessPermission] === 'READ'} className='my-3 ml-5' form={form} layout="vertical" onFinish={onFinish}>
                {menuList.map((parent) => (
                    <div className='border-b' key={parent?.value} style={{ marginBottom: '10px' }}>
                        {/* {console.log(parentChecks.includes(parent))} */}
                        <Form.Item className='!m-0' name={`${parent?.value}-parent`} valuePropName="checked" >
                            <Checkbox
                                onChange={handleParentChange(parent?.value)}
                            // value={parentChecks?.includes(parent?.key)}
                            >
                                {parent?.label}
                            </Checkbox>
                        </Form.Item>
                        <Form.Item className='ml-5 mb-2' name={parent?.value}>
                            <CheckboxGroup
                                style={{ display: 'flex', flexDirection: 'column' }}
                                options={childOptions}
                                // value={form.getFieldValue(parent?.key)}
                                onChange={handleChildChange(parent?.value)}
                            >
                                {(checkboxes) =>
                                    checkboxes.map((checkbox) => (
                                        <div key={checkbox.value}>
                                            <Checkbox value={checkbox.value}>{checkbox.label}</Checkbox>
                                        </div>
                                    ))
                                }
                            </CheckboxGroup>
                        </Form.Item>
                    </div>
                ))}
                <Form.Item className='mt-5'>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default AccessForm;
