import { Card, Row } from 'antd';
import React, { useState } from 'react'
import Heading from '../../../../component/common/Heading';
import CRUDComponent from '../../../../component/common/CRUD-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { taskCategoryExportData } from '../../../../util/exportData';

const Category = ({ accessPermission }) => {
    const [extraQuery, setExtraQuery] = useState({sort: "ranking"})

    return (
        <>
            <div>
                <Card className="my-5">
                    <Heading>Task Category</Heading>
                    <Row>
                        <CRUDComponent
                            title='Task Category'
                            exportData={taskCategoryExportData}
                            accessPermission={accessPermission}
                            GET={{
                                API: CONSTANTS.API.setting.task_category.getAll,
                                extraQuery,
                                DataModifier: (res, API, Setrefresh) => {
                                    return res?.map(data => {
                                        return {
                                            ...data,
                                            no: `TC${data?.id.toString().padStart(7, "0")}`,
                                            ranking: {
                                                data: data?.ranking,
                                                onBlur: (Rank) => {
                                                    if (!isNaN(Number(Rank))) {
                                                        const UpdateAPIEnd = { ...CONSTANTS.API.setting.task_category.update };
                                                        UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${data?.id}`;
                                                        API.sendRequest(
                                                            UpdateAPIEnd,
                                                            () => {
                                                                Setrefresh((prev) => !prev);
                                                            },
                                                            { ranking: Number(Rank) },
                                                            `Ranking Update Successfully`
                                                        );
                                                    }
                                                }
                                            },

                                        }
                                    });
                                },
                                column: CONSTANTS.TABLE.TASK_CATEGORY,
                            }}
                            isSearch
                            UPDATE={{
                                API: CONSTANTS.API.setting.task_category.update,
                                message: "Edit Task Category successfully",
                                modaltitle: "Edit Task Category",
                                modalFields: CONSTANTS.FORM_FIELD.TASK_CATEGORY_MODAL,
                                // payloadModifier: (res) => res,
                            }}
                            CREATE={{
                                API: CONSTANTS.API.setting.task_category.add,
                                message: "Created Task Category successfully",
                                modaltitle: "Add Task Category",
                                modalFields: CONSTANTS.FORM_FIELD.TASK_CATEGORY_MODAL,
                                // payloadModifier: (res) => res,
                            }}
                            DELETE={{
                                API: CONSTANTS.API.setting.task_category.delete,
                                message: "Deleted Task Category Successfully",
                            }}
                        />
                    </Row>
                </Card>

            </div>
        </>
    )
}

export default Category